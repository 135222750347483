import { biVipLink } from "../../sharedfiles/EndpointConfig";

export const VIP_BI_REPORTS_DEV = [
    {
        id: 1,
        ReportCatagory: "VIP Reports",
        Reports: [
            {
                id: 1,
                name: "Dashboard FasFas Reports",
                url: biVipLink.DashboardFasFas
            },
            {
                id: 2,
                name: "Loyalty VIP Reports",
                url: biVipLink.LoyalityVip
            }
        ]
    }]
export const VIP_BI_REPORTS_PROD = [
    {
        id: 2,
        ReportCatagory: "VIP Reports",
        Reports: [
            {
                id: 1,
                name: "Dashboard FasFas Reports",
                url: biVipLink.DashboardFasFas
            },
            {
                id: 2,
                name: "Loyalty VIP Reports",
                url: biVipLink.LoyalityVip
            }
        ]
    }]

